<template>
  <div class="mt-4  px-5">

    <h2>Genehmigungen</h2>

    <v-alert
        dense
        text
        type="info">
      <b>Tipp:</b>
      <div>Hier werden Einträge angezeigt, die euch Eure Mitglieder zur Freigabe einreichen.<br>
          Um ein Training zu bestätigen klickt auf freigabe.
      </div>
    </v-alert>

    <v-data-table
        :headers="headers"
        :items="comp_ShootingbookforApproval"
        sort-by="nummer"
        class="elevation-1"
        ref="Shootingbook"
        :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn icon
                 @click="getShootingbook">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.beginn="{ item }">
        <span>{{ new Date(item.beginn.replace(" ", "T")).toLocaleString() }}</span>
      </template>
      <template v-slot:item.ende="{ item }">
        <span>{{ new Date(item.ende.replace(" ", "T")).toLocaleString() }}</span>
      </template>

            <template v-slot:item.approval="{ item }">
              <v-btn
                  v-if="item.bestaetigung_holen == true"
                  class="btn-info"
                  color="info"
                  @click="setApproval(item)"
              >
                <v-icon
                    class="mr-2"
                    x-small>fas fa-hands-helping</v-icon>
                freigabe

              </v-btn>

            </template>

      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'authorization',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    loading: false,
    headers: [
      {
        align: 'Name',
        sortable: false,
      },
      {text: 'Name', value: 'name'},
      {text: 'Vorname', value: 'vorname'},
      {text: 'Stand', value: 'KurzBez'},
      {text: 'Bahn', value: 'bahn'},
      {text: 'Funktion', value: 'Aufsicht'},
      {text: 'Waffe', value: 'KurzT'},
      {text: 'Disziplin', value: 'LangBez'},
      {text: 'Begin', value: 'beginn'},
      {text: 'Ende', value: 'ende'},
      {text: 'Verein freigabe', value: 'approval', sortable: false}
    ],

  }),

  computed: {
    comp_ShootingbookforApproval() {
      return this.$store.state.ClubApp.ShootingbookApproval;
    },

  },

  mounted() {
    this.getShootingbook();
  },

  methods: {

    getShootingbook() {
      this.$store.dispatch('ClubApp/getShootingbookforApproval')
    },
    setApproval(item) {
      this.loading = true;
      var Data = {
        freigabeID: item.id,
        freigabebool: 1,
      };
      this.$store.dispatch('ClubApp/setShootingRecordApproval', Data)
          .then(this.loading = false);
    },
    initialize() {
      this.getShootingbook();
    },

  },
}
</script>

<style scoped>

</style>

